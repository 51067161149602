<script setup>

import TransportLayout from "../../../layouts/TransportLayout.vue";
import { onMounted, ref } from "vue";
import { useRoute } from "vue-router/auto";
import useTransportOrder  from "../../../hooks/useTransportOrders.js";
import { setPageTitle } from "@/utils/pageTitle";
import { useToast } from "vue-toastification";
import c3api from "@/c3api";

const route = useRoute();
const toast = useToast();
const uploading = ref(false);

const {
    fetchTransportOrder,
    order,
    fetchDocuments,
    documents,
} = useTransportOrder();
onMounted(async () => {
    await fetchTransportOrder(route.params.id);
    await fetchDocuments(route.params.id);
    setPageTitle(`Transportation Orders // ${order.value?.id}`);
});

const getIconType = (file) => {
  if (file.mime_type.includes('pdf')) return 'file-pdf'
  if (file.mime_type.includes('text')) return 'file-pdf'
  if (file.mime_type.includes('csv')) return 'file-csv'
  return 'image'
}

const upload = async (e) => {
  uploading.value = true;
  const file = e.target.files[0];
  const formData = new FormData();
  formData.append("file", file);
  try {
    const res = await c3api.post(`/transport_orders/${order.value.id}/order_documents`, formData, {
        headers: {
            "Content-Type": "multipart/form-data",
        },
    });
    toast.success("File uploaded successfully.");
    await fetchDocuments(route.params.id);
  } catch (error) {
    toast.error(error.response.data.data.join(". "));
  } finally {
    uploading.value = false;
  }
}
</script>

<template>
    <TransportLayout v-if="order" :transportOrder="order"> 
        <div class="d-flex flex-wrap pa-2">
            <v-card
              v-for="doc in documents" :key="doc.id"
              class="ma-2"
              max-width="250"
              max-height="300"
              variant="outlined"
              link
              target="_blank"
              :href="doc.url"
            >
              <v-img v-if="getIconType(doc) === 'image'"
                height="100px"
                :src="doc?.url"
              >
                  <template v-slot:placeholder>
                      <div class="d-flex align-center justify-center fill-height">
                          <v-progress-circular
                              color="grey-lighten-4"
                              indeterminate
                          ></v-progress-circular>
                      </div>
                  </template>
              </v-img>
              <div v-else class="text-center mt-4">
                <v-icon size="45" icon="mdi-file" />
              </div>
              <v-card-title class="text-wrap">{{ doc.filename }}</v-card-title>
              <v-card-subtitle class="text-wrap">{{ doc.created_at }}</v-card-subtitle>
    
              <v-card-text>
                <div>Added by {{ doc.created_by.name }}</div>
              </v-card-text>
            </v-card>

            <v-card
              variant="outlined"
              class="ma-2 d-flex align-center justify-center"
              max-width="250"
              min-height="75"
              @click="$refs.file.click()"
            >
              <v-icon size="45" v-if="!uploading" icon="mdi-plus" />

                <v-progress-circular
                  v-if="uploading"
                  :width="8"
                  :size="50"
                  color="primary"
                  class="mb-16"
                  indeterminate
                ></v-progress-circular>

            </v-card>
            <input v-show="false" type="file" ref="file" @change="upload" />
          </div>
    </TransportLayout>
</template>